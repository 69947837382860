





















































































































































import {Component} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {mixins} from 'vue-class-component';

import Location from '@/models/Location';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {validationMixin} from 'vuelidate';
import CleanTime from '@/models/CleanTime';
import {convertMs, getFormattedTime} from '@/helper/JobTimeHelper';
import {TimeDuration} from '@/helper/TimeDuration';
import moment from 'moment';
import {WEEKDAYS} from '@/Constants';
import {cleanTimeStoreActions, cleanTimeStoreGetter} from '@/stores/cleanTime.store';
import MenuWithPickerComponent from '@/components/shared/MenuWithPicker.component.vue';
import {Permission} from '@/misc/enums/permission.enum';

const CustomerStore = namespace('customer');
const cleanTimeStore = namespace('cleanTime');
const JobStore = namespace('job');

/**
 *  Component to show the cleanTimes of a location
 */
@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  mixins: [validationMixin],
  validations: {},
  components: {
    MenuWithPickerComponent,
    UserInitialsComponent: () => import(
      /* webpackChunkName: "UserInitialsComponent" */
      '@/components/user/UserInitials.component.vue'),
    CleanTimePickerComponent: () => import(
      /* webpackChunkName: "CleanTimePickerComponent" */
      '@/components/shared/CleanTimePicker.component.vue'),
    PaginationComponent: () => import(
      '@/components/shared/table/Pagination.component.vue'),
    ActionMenuComponent: () => import(
      '@/components/shared/table/ActionMenu.component.vue'),
  },
})
export default class LocationCleanTimeOverviewComponent extends mixins(ErrorMessageHandlerMixin) {
  //region Store, Actions, Getter & Mutations
  @cleanTimeStore.Action(cleanTimeStoreActions.DELETE_CLEAN_TIME_ACTION)
  public deleteCleanTimeAction!: (cleanTimeId: string) => Promise<void>;
  @cleanTimeStore.Action(cleanTimeStoreActions.UPDATE_CLEAN_TIME_ACTION)
  public updateCleanTimeAction!: (payload: { cleanTime: CleanTime, splitAtDate: string }) => Promise<CleanTime>;
  @cleanTimeStore.Action(cleanTimeStoreActions.GET_CLEAN_TIME_ACTION)
  public getCleanTimeAction!: (cleanTimeId: string) => Promise<CleanTime>;
  public openDeleteDialog: boolean = false;

  //#endregion
  public isLoading: boolean = true;
  public selectedCleanTime: CleanTime = new CleanTime();
  public showChangeStatusDialog: boolean = false;
  @CustomerStore.Getter('location')
  public location!: Location;
  @cleanTimeStore.Getter(cleanTimeStoreGetter.CLEAN_TIMES)
  public cleanTimes!: CleanTime[];
  @JobStore.Mutation('clearCachedJobs')
  private clearCachedJobs!: () => void;
  private page: number = 1;
  private itemsPerPage: number = 0;
  private cancelDate: string = '';
  private noDate: string = '';

  /**
   * Returns an error of objects with the needed information for the vuetify table
   */
  get tableHeaders(): any[] {
    return [
      {
        text: this.$t('CLEANTIME_OVERVIEW.HEADER.DATE').toString(),
        value: 'date',
        class: 'table_header--text',
        sortable: false,
      },
      {
        text: this.$t('CLEANTIME_OVERVIEW.HEADER.DURATION').toString(),
        value: 'times',
        class: 'table_header--text',
        width: '15%',
      },
      {
        text: this.$t('CLEANTIME_OVERVIEW.HEADER.EMPLOYEE').toString(),
        value: 'plannedUsers',
        class: 'table_header--text',
        sortable: false,
      },
      {
        text: this.$t('CLEANTIME_OVERVIEW.HEADER.RECURRENCE').toString(),
        value: 'recurrence',
        class: 'table_header--text',
      },
      {
        text: this.$t('CLEANTIME_OVERVIEW.HEADER.FREQ').toString(),
        value: 'frequency',
        class: 'table_header--text',
        sortable: false,
      },
      {
        text: this.$t('CLEANTIME_OVERVIEW.HEADER.AREA').toString(),
        value: 'areas',
        class: 'table_header--text',
        width: '15%',
      },
      {text: this.$t('GENERAL.STATUS'), value: 'active', class: 'table_header--text'},
      {text: '', value: 'actions', class: 'table_header--text', sortable: false},
    ];
  }

  public async mounted() {
    await this.loadCleanTimes();
    this.location.cleanTimes = this.sortOutDatedCleanTimes(this.location.cleanTimes);
  }

  get isActive() {
    const inactive: string = (this.$t('GENERAL.INACTIVE') as string).toLowerCase();
    const active: string = (this.$t('GENERAL.ACTIVE') as string).toLowerCase();

    return {
      opposite: this.selectedCleanTime!.active ? inactive : active,
    };
  }

  /**
   * iterates through all the cleanTimes and sorts the ones who are outDated to the bottom
   */
  public sortOutDatedCleanTimes(items: CleanTime[]): CleanTime[] {
    // sorts every item in the passed array of CleanTimes
    return items.sort((a, b) => {

      // checks if a or b is outdated
      const outDateA = a.isOutDated;
      const outDateB = b.isOutDated;

      // if a is outdated and b isn't
      if (outDateA && !outDateB) {
        return 1;
      }

      // if b is outdated and a isn't
      if (!outDateB && outDateA) {
        return -1;
      }

      // default return if both or none of them are outdated or an additional case occurred
      return -1;
    });
  }

  /**
   * Custom Sorting for the Clean Time Overview,
   * sorts according to descending or ascending and the
   * table header
   */
  public customFilter(items: CleanTime[], sortBy: string[], sortDesc: boolean[], locale: string, customSorters?: any): CleanTime[] {
    // Get Table Row and orientation to sort for
    const sortByHeader: string = sortBy[0];
    const sortByDesc: boolean = sortDesc[0];

    // return all items if no sorting is enabled
    if (sortByDesc === undefined && sortByHeader === undefined) {
      return this.sortOutDatedCleanTimes(items);
    }

    // Sort according to the header
    switch (sortByHeader) {
      case 'date':
        // TODO: sort for the next job in timeline if wanted
        items = items.sort((a, b) => {
          if (sortDesc) {
            return 1;
          }
          return 0;
        });
        return this.sortOutDatedCleanTimes(items);
      case 'recurrence':
        items = items.sort((a, b) => {
          if (sortByDesc) {
            return b.freq.localeCompare(a.freq);
          }
          return a.freq.localeCompare(b.freq);
        });
        return this.sortOutDatedCleanTimes(items);
      case 'times':
        items = items.sort((a, b) => {
          // Get TimesDuration Object from A and B
          const timeA = this.getSortedTime(a.times, sortByDesc);
          const timeB = this.getSortedTime(b.times, sortByDesc);

          // Check if we are sorting decending or acending, return appropiate
          if (sortByDesc) {
            return timeB.hour - timeA.hour;
          }
          return timeA.hour - timeB.hour;
        });
        return this.sortOutDatedCleanTimes(items);
      case 'locations':
        items = items.sort((a, b) => {
          // Sort with length
          if (sortByDesc) {
            return b.areas.length - a.areas.length;
          }
          return a.areas.length - b.areas.length;
        });

        return this.sortOutDatedCleanTimes(items);
      case 'active':
        // Sort by convertig the booleans to numbers
        items.sort((a, b) => {
          if (sortByDesc) {
            return Number(b.active) - Number(a.active);
          }
          return Number(a.active) - Number(b.active);
        });
        return this.sortOutDatedCleanTimes(items);
      default:
        return this.sortOutDatedCleanTimes(items);
    }
  }

  /**
   * loads the colors for ???
   */
  public async loadCleanTimes(): Promise<void> {
    // Set Overview and Dashboard to Loading State

    this.$emit('setLoading', true);

    const cleanTimes = this.location.cleanTimes.map((cleanTime) => {
      return this.getCleanTimeAction(cleanTime.id!);
    });

    this.location.cleanTimes = await Promise.all(cleanTimes);

    // Enables the Tabmodel buttons again after the loading is finished
    this.$emit('setLoading', false);

    // Set is Loading to False
    this.isLoading = false;
  }

  /**
   * Returns the time sorted (descending or ascending)
   */
  public getSortedTime(times: TimeDuration[], desc = false): TimeDuration {
    return times.sort((a, b) => {
      if (desc) {
        return b.hour - a.hour;
      }
      return a.hour - b.hour;
    })[0];
  }

  /**
   * Method that is called when user clicks on a row
   * opens the edit popUp with the cleanTime of the row
   */
  public onRowClicked(item: CleanTime) {
    if (this.$userRoleHandler.hasPermission(Permission.CLEANTIME_UPDATE_OWN)) {
      this.onEditCleanTime(item);
    }
  }

  public getCleanTimeDate(item: CleanTime): string {
    // The start and end Date
    let date = moment(item.date).format(`${this.$t('GENERAL.DATE_FORMATS.DATE')}`);
    if (item.dateEnd && item.dateEnd?.length > 0) {
      date += ' - ' + moment(item!.dateEnd).format(`${this.$t('GENERAL.DATE_FORMATS.DATE')}`);
    }
    return date;
  }

  /**
   * Returns a translated string for the recurrence of a clean time
   * includes the interval in its message
   */
  public getCleanTimeRecurrence(item: CleanTime): string {
    return this.$t(`CLEANTIME_OVERVIEW.RECURRENCE.${item.freq.toUpperCase()}`, {interval: item.interval}).toString();
  }

  /**
   * Returns a string representation of the byWeekArray found in the cleanTime
   */
  public getCleanTimeFrequency(cleanTime: CleanTime): string {
    let days = '';

    // Check if all days were selected
    if (cleanTime.byWeekDay === null) {
      cleanTime.byWeekDay = WEEKDAYS.map((val: string) => val);
    }

    // If every day return with appropiate message
    if (cleanTime.byWeekDay.length >= 7) {
      return this.$t(`CLEANTIME_OVERVIEW.FREQUENCY.EVERY_DAY`).toString();
    }

    // go through all days in our clean Time and create the correct day message
    for (let i = 0; i < cleanTime!.byWeekDay!.length; i++) {
      const day = cleanTime!.byWeekDay![i];

      // Find matches with regex, one for just the characters and one for just the numbers
      const regDay = day!.match(/[a-z]+/)![0];
      const recurrence = day!.match('[\\d -]+') ? day!.match('[\\d -]+')![0] : undefined;

      // if no recurrence in day string just add the day
      if (!recurrence) {
        // add day
        const translatedDay = this.$t(`GENERAL.DAYS_SHORT.${regDay.toUpperCase()}`);
        days += `${translatedDay}${i === (cleanTime.byWeekDay.length - 1) ? '' : ', '}`;

        continue;
      }

      // Add day with recurrence
      const translatedText = this.$t(`CLEANTIME_OVERVIEW.FREQUENCY.${recurrence}`, {
        day: this.$t(`GENERAL.DAYS.${regDay.toUpperCase()}`),
      });
      days += `${translatedText} <br />`;
    }

    return days;
  }

  /**
   * Calculates a and returns a String representation for the clean time duration
   */
  public getCleanTimeDuration(cleanTime: CleanTime): string {
    let timeString = '';

    for (const times of cleanTime.times) {
      const duration = convertMs(times.duration);

      // If minutes are over 60, another hour is added to the hour
      if (times.minute >= 60) {
        times.hour++;
        times.minute -= 60;
      }

      // checks if the endTime is over 24 hours and if so the hours are reduced by 24 (e.g. 26:00 is changed to 02:00)
      const startTimeHours = times.hour;
      const startTimeMinutes = times.minute;
      let endTimeHours = startTimeHours + duration.hour;
      let endTimeMinutes = startTimeMinutes + duration.minute;

      if (endTimeMinutes >= 60) {
        endTimeHours += 1;
        endTimeMinutes -= 60;
      }
      if (endTimeHours >= 24) {
        endTimeHours -= 24;
      }

      timeString += `${getFormattedTime(startTimeHours, startTimeMinutes)} - ${getFormattedTime(endTimeHours, endTimeMinutes)} ${cleanTime.times.length > 1 ? '<br />' : ''}`;
    }

    return timeString;
  }

  /**
   * Returns a string to represent the areas. If the cleanTime has only one area, display its name, the amount otherwise
   */
  public getCleanTimeAreas(cleanTime: CleanTime): string {

    // Check if areas are defined. If not show none msg
    if (cleanTime.areas.length <= 0) {
      return this.$t('CLEANTIME_OVERVIEW.LOCATIONS.NONE').toString();
      // If more than one were found show a correct msg
    } else if (cleanTime.areas.length > 1) {
      return this.$t('CLEANTIME_OVERVIEW.LOCATIONS.MORE_THAN_ONE', {count: cleanTime.areas.length}).toString();
      // if only one was found show his name
    } else {
      return cleanTime.areas[0].name;
    }
  }

  /**
   * Deletes a CleanTime, by sending an request to the api
   * @param cleanTimeId
   */
  public async onDeleteCleanTime(cleanTimeId: string): Promise<void> {
    try {
      // Send API Request to delete the cleantime with the given id
      await this.deleteCleanTimeAction(cleanTimeId);
      // Show Success message
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_CLEAN_TIME.SUCCESS');

      // If API send back a cleanTime Document is probably deleted
      // try to find the given cleanTime in our Location cleantimes array
      const indexToDelete = this.location.cleanTimes.findIndex((cleanTime) => cleanTime.id === cleanTimeId);

      // If found delete from array
      if (indexToDelete > -1) {
        this.location.cleanTimes.splice(indexToDelete, 1);
      }

      // Clear Calendar Cache on Remove
      this.clearCachedJobs();

      // Close Delete Dialog
      this.openDeleteDialog = false;
      // catch all errors
    } catch (e: any) {
      // CleanTime was already deleted (by another user)
      if (e.status === 409) {
        this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_CLEAN_TIME.ERROR.ALREADY_DELETED');
      } else {
        this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_CLEAN_TIME.ERROR.GENERAL');
      }
    }
  }

  /**
   * Changes the Active Status of a Clean Time by sending an api Request
   */
  public async onChangeStatusOfCleanTime(): Promise<void> {
    if (!this.cancelDate) {
      this.noDate = this.$t('CLEANTIME_OVERVIEW.INFOS.NO_VALID_AT_DATE.TEXT').toString();
    } else {
      this.noDate = '';
      try {
        // Copy cleantime as its own object
        const cleanTimeCopy = CleanTime.parseFromObject(this.selectedCleanTime);
        // Set Status for this Clean Time
        cleanTimeCopy.active = !this.selectedCleanTime.active;
        // Remove Recurrence from times array
        for (const time of cleanTimeCopy!.times!) {
          delete time.occurrences;
        }
        // to populate tasks
        await this.getCleanTimeAction(this.selectedCleanTime.id!).then((cleanTime) => cleanTimeCopy.areas = cleanTime.areas);
        // Check if byWeekDay is the full week
        if (cleanTimeCopy.byWeekDay!.length >= 7) {
          cleanTimeCopy.byWeekDay = null;
        }
        // Send API Request to update the cleantime with the given id
        const updated = await this.updateCleanTimeAction({cleanTime: cleanTimeCopy, splitAtDate: this.cancelDate});
        this.clearCachedJobs();
        // If API send back a cleanTime Document is probably deleted
        if (updated) {
          // Clean Time Updated, update in our local list
          const indexToUpdate = this.location.cleanTimes.findIndex((curCleanTime) => curCleanTime.id === updated.id);
          // If found update in array
          if (indexToUpdate > -1) {
            this.location.cleanTimes.splice(indexToUpdate, 1, updated);
          }
          this.showChangeStatusDialog = false;
          // Show Success message
          this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.EDIT_CLEAN_TIME.SUCCESS');
        }
        // catch all errors
      } catch (e: any) {
        this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.EDIT_CLEAN_TIME.ERROR');
      }
    }
  }

  public onEditCleanTime(cleanTime: CleanTime): void {
    this.$emit('editCleanTime', cleanTime);
  }

  private getColor(color: string): string {
    return this.$colorHandler.getThemeColor(color);
  }

  private validFromMinMax: { min: string, max: string } = {
    min: moment().toISOString(),
    max: '',
  };

  private onStatusToggleClick(event: Event, item: CleanTime) {
    if (this.$userRoleHandler.hasPermission(Permission.CLEANTIME_UPDATE_OWN)) {
      this.selectedCleanTime = item;
      this.showChangeStatusDialog = true;
      this.cancelDate = '';
      this.noDate = '';
      event.stopPropagation();
    }
  }

  private onDeleteClick(item: any) {
    this.selectedCleanTime = item;
    this.openDeleteDialog = true;
  }

  private onCopyClick(cleanTime: CleanTime) {
    this.$emit('copyCleanTime', cleanTime);
  }
}
